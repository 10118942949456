module.exports.backend = {
    apiUrl: "https://dev.vpicking.venturing.com.ar/vPickingAR/api",
    wsUrl: "wss://dev.vpicking.venturing.com.ar",
    wsPath: "/vPickingAR/ws/"
};

module.exports.weight = {
    numberOfPercent : 1.5,
    toleranceValue: 0.02,
};

module.exports.sizeOfPdf = {
	width: 425,
	high: 566,
};

module.exports.countryVersion = {
    currentCountry: 'AR', // AR - CL - MX - CO - PE
    pickUpFeatures: true,
    noPostalCode: false,
    backLogDashboard: true,
    linkLabelsForPP: true,
};

module.exports.defaultBox = {
    size: 0
};

module.exports.giftsFeature = {
    active: true
};

module.exports.validatePicking = {
    active: true
};

module.exports.pickUp = {
    maximum_days_of_storage: 0,
};

module.exports.validateDelivery = {
  active: true
}
